import config from 'config';
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery';

export function prepareRelatedQuery(key, sku) {
  let relatedProductsQuery = new SearchQuery();

  relatedProductsQuery = relatedProductsQuery.applyFilter({ key: key, value: { in: sku } });

  relatedProductsQuery = relatedProductsQuery
    .applyFilter({ key: 'visibility', value: { in: config.zento.queryFilters.related.visibility } })
    .applyFilter({ key: 'status', value: { in: config.zento.queryFilters.related.status } });

  if (config.products.listOutOfStockProducts === false) {
    relatedProductsQuery = relatedProductsQuery.applyFilter({ key: 'stock.is_in_stock', value: { eq: true } });
  }

  return relatedProductsQuery;
}
